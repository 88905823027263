<template>
  <div class="file-manager-application">
    <div class="content-overlay"></div>
    <div class="header-navbar-shadow"></div>
    <div class="content-area-wrapper container-xxl p-0">
      <div class="sidebar-left">
        <div class="sidebar">
          <div class="sidebar-file-manager" :class="showMenu ? 'show' : null">
            <div class="sidebar-inner">
              <div class="sidebar-list">
                <div class="list-group">
                  <div class="my-drive"></div>
                  <b-link class="list-group-item list-group-item-action d-flex justify-content-start align-items-center" :to="{name: !$route.params.id ? 'admin-dashboard.new' : 'admin-dashboard.general-data' }" exact-active-class="active">
                    <feather-icon icon="FolderIcon" size="16" class="mr-1" />
                    Empresa
                  </b-link>
                  <b-link class="list-group-item list-group-item-action d-flex justify-content-start align-items-center" :to="{name: 'admin-dashboard.representatives'}" exact-active-class="active" :disabled="!$route.params.id">
                    <feather-icon icon="UserIcon" size="16" class="mr-1" />
                    Representantes legais
                  </b-link>
                  <b-link class="list-group-item list-group-item-action d-flex justify-content-start align-items-center" :to="{name: 'admin-dashboard.documents'}" exact-active-class="active" :disabled="!$route.params.id">
                    <feather-icon icon="FileTextIcon" size="16" class="mr-1" />
                    Análise de documentos
                  </b-link>
                  <b-link class="list-group-item list-group-item-action d-flex justify-content-start align-items-center" :to="{name: 'admin-dashboard.tax'}" exact-active-class="active" :disabled="!$route.params.id">
                    <feather-icon icon="PercentIcon" size="16" class="mr-1" />
                    Taxas e limites
                  </b-link>
                  <b-link class="list-group-item list-group-item-action d-flex justify-content-start align-items-center" :to="{name: 'admin-dashboard.invite'}" exact-active-class="active" :disabled="!$route.params.id">
                    <feather-icon icon="UserCheckIcon" size="16" class="mr-1" />
                    Convites
                  </b-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content-right">
        <div class="content-wrapper container-xxl p-0">
          <div class="body-content-overlay" :class="showMenu ? 'show' : null" @click="hideMenu" />
          <div class="content-body">
            <div class="file-manager-main-content">
              <div class="file-manager-content-header d-flex justify-content-between align-items-center">
                <div class="d-flex align-items-center">
                  <div class="sidebar-toggle d-block d-xl-none float-left align-middle ml-1">
                    <feather-icon icon="MenuIcon" size="26" class="font-medium-5" @click="showMenu = !showMenu" />
                  </div>
                  <div class="input-group input-group-merge shadow-none m-0 flex-grow-1">
                    <h4 class="p-1 mb-0">{{ $route.meta.title }}</h4>
                  </div>
                </div>
              </div>
              <div class="file-manager-content-body">
                <router-view />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import AppFooter from '@core/layouts/components/AppFooter.vue'
import { BLink } from 'bootstrap-vue';

export default {
  components: {
    LayoutVertical,
    AppFooter,
    BLink
  },
  data() {
    return {
      showMenu: false
    }
  },
  methods: {
    hideMenu() {
      this.showMenu = false
    }
  },
  watch: {
    $route() {
      this.hideMenu()
    }
  }
}
</script>

<style lang="scss">
.content-area-wrapper {
  height: auto!important;
}
</style>
